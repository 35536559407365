body {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #f4f4f4;
}


header {
    font-family: 'Quicksand', sans-serif;
    background: #333;
    color: #fff;
    padding: 10px 0;
    text-align: center;
}

nav {
    display: flex;
    justify-content: center;
    align-items: center;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

nav li {
    margin: 0 15px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
}

nav a {
    color: white;
    text-decoration: none;
    padding: 10px;
    display: block;
}

nav a:hover {
    background-color: #555;
}

main {
    padding: 20px;
}

footer {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
}
