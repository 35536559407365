.privacy-policy {
    display: flex;
    flex-direction: column;
    /* Remove the height so the page is not forced to 100vh */
    height: auto;
    margin-top: 65px;
  }

.terms-container {
    padding-top: 0px;
    margin-top: 80px;
    padding: 20px;
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

/* Specific class for Last Updated paragraphs */
.last-updated {
    padding-left: 0; /* Remove left padding */
    margin-top: 1.5em; /* Adjust margin-top to align with H2 */
    margin-bottom: 0.5em; /* Adjust margin-bottom to align with H2 */
    text-align: left; /* Ensure text is aligned to the left */
}

/* General H1 styles */
.terms-container h1 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 900;
    text-align: center; /* Note: Changed from 'center' string to center */
}

/* H2 styles */
.terms-container h2 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 900;
    color: #333;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    text-align: start; /* This aligns text to the left */
}

/* H3 styles */
.terms-container h3 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 900;
}

.rights {
    margin-top: 2em;
}

/* General paragraph styles */
.terms-container p {
    font-family: 'Quicksand', sans-serif;
    color: #555;
    /*padding-left: 1em; /* This adds left padding to regular paragraphs */
    margin-bottom: 1em;
    text-align: left; /* Ensures text is aligned to the left */
}

.details {
    padding-left: 1em;
}

/* Unordered list styles */
.terms-container ul {
    font-family: 'Quicksand', sans-serif;
    /*padding-left: 1.5em; /* Padding for list items */
}

.collect {
    padding-left: 2em;
}

.list {
    padding-left: 1.5em;
}

/* List item styles */
.terms-container ul li {
    margin-bottom: 0.5em;
}

/* Strong tag styles */
.terms-container strong {
    color: #000;
}   

footer nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px; /* Adjust spacing between links */
}

footer nav ul li {
    display: inline;
}

footer nav ul li a {
    text-decoration: none;
    color: #7323eb; /* Set link color */
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
}

footer .footer-signature {
    position: absolute;
    bottom: 2px;
    margin-top: auto;
    right: 15px;
    color: #7323eb;
    font-family: 'Quicksand', sans-serif;
    font-size: .8em;
    display: flex;
}


/* Responsive Styles */
@media (max-width: 768px) { /* For mobile screens */
    .privacy-policy .terms-container {
        padding: 10px; /* Reduce padding on mobile for better spacing */
    }

    .privacy-policy h1 {
        font-size: 1.5em; /* Smaller heading for mobile */
    }

    .privacy-policy h2 {
        font-size: 1.2em; /* Adjust heading sizes for mobile */
    }

    .privacy-policy p,
    .privacy-policy ul {
        font-size: 0.9em; /* Slightly smaller text on mobile */
    }

    .privacy-policy .header-title-container {
        flex-direction: row; /* Stack titles vertically on mobile */
        align-items: center; /* Center align titles */
    }

    .privacy-policy .header-title {
        letter-spacing: -2px; /* Adjust as needed */
    }

    .privacy-policy nav a {
        padding: 8px; /* Adjust padding for mobile */
        text-align: center; /* Center the link text */
    }

}

