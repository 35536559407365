/* Main container for the page */
 .supportPage {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
  }
  
  /* Styling for the header */
  .supportPage .header {
    position: fixed;
    top: 0; /* Set the position to the top of the screen */
    left: 0; /* Align it to the left */
    width: 100%; /* Make sure the header spans the full width of the screen */
    background-color: #fdfdfd;
    color: white;
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .header {
    position: fixed;
    top: 0; /* Set the position to the top of the screen */
    left: 0; /* Align it to the left */
    width: 100%; /* Make sure the header spans the full width of the screen */
    background-color: #fdfdfd;
    color: white;
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .header img {
    width: 50%;
    max-width: 300px;
  }
  
  .menuButton {
    position: absolute;
    justify-content: center;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
    color: #7323eb;
  }
  
  /* Side menu that will slide in from the right */
  .sideMenu {
    position: fixed;
    top: 0;
    right: -250px; /* Initially hidden off-screen */
    width: 220px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column; /* Ensure vertical stacking of elements */
    padding-top: 20px;
  }

  .sideMenu img {
    position: absolute;  /* Position relative to the side menu */
    bottom: 15px;
    width: 80%; /* Optional: Adjust the image size if needed */
    max-width: 250px; /* Optional: Ensure it doesn't grow too large */
    left: 50%;           /* Optional: Center the image horizontally */
    transform: translateX(-50%);  
  }
  
  .sideMenu.open {
    right: 0; /* Slide in when the menu is open */
  }
  
  /* Ensure vertical stacking for the navigation links */
  .sideMenu nav ul {
    list-style-type: none;  /* Remove bullet points */
    padding: 0;             /* Remove padding */
    margin: 0;              /* Remove margin */
    display: flex;          /* Flexbox layout */
    flex-direction: column; /* Ensure the links are stacked vertically */
    align-items: start;
  }
  
  .sideMenu li {
    margin: 10px 0; /* Add space between links */
  }
  
  .sideMenu a {
    color: #8438f5;
    text-decoration: none;
    font-weight: 800;
    font-family: 'Quicksand', sans-serif; 
    display: block; /* Ensures the anchor tag takes full width */
  }
  
  .sideMenu a:hover {
    text-decoration: underline;
  }
  
  /* Close button for the menu */
  .closeButton {
    position: absolute;
    top: 10px;
    background-color: transparent;
    border: none;
    font-size: 30px;
    color: #7323eb;
    cursor: pointer;
  }
  
  /* Styling for links in header and other sections */
  .header li {
    color: #7323eb;
  }

  .supportPage h1 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 900;
    color: #7323eb;
    text-align: center; 
    margin: 0;
}
  
  .supportPage h2 {
    color: #7323eb;
    font-weight: 600;
    margin: 0;
  }

  .supportPage h3 {
    text-align: left;
    font-weight: 600;
    margin: 0px 0px 5px 0px;
  }
  
  .section {
    align-self: center;
    flex-direction: column; /* Stack children vertically */
    align-items: center;     /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    max-width: 800px;
    width: 85%;
    padding: 20px;
    margin: 10px auto;
    margin-top: 70px;
    margin-bottom: 2px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flexbox;
  }
  
  .section2 {
    align-self: center;
    flex-direction: column; /* Stack children vertically */
    align-items: center;     /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    max-width: 800px;
    width: 85%;
    padding: 20px;
    margin: 10px auto;
    margin-bottom: 2px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flexbox;
  }

  .section3 {
    align-self: center;
    flex-direction: column; /* Stack children vertically */
    align-items: center;     /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    max-width: 800px;
    width: 85%;
    padding: 20px;
    margin: 10px auto;
    margin-bottom: 2px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flexbox;
  }
  
  .supportPage ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  li {
    margin: 15px 0;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
  }

  .section2 li {
    margin: 5px 0 25px 0px;
    color: #535354;
  }

  .section3 h3 {
    text-align: center;
  }

  .section3 li {
    text-align: center;
  }
  
  a {
    color: #8438f5;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .contactButton {
    background-color: #8438f5;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .contactButton:hover {
    background-color: #7323eb;
  }
  
  /* Footer Styling */
  .footer {
    text-align: center;
    margin-top: auto;
    background-color: #f4f4f4;
    color: #7323eb;
    padding: 10px 0;
  }
  
  .signature {
    padding: 10px;
    margin-top: 20px;
    background-color: #fff;
    font-size: 14px;
    color: #7323eb;
    border-top: 1px solid #ccc;
  }

  /* Desktops (1024px and up) */
@media (min-width: 1024px) {
    .supportPage .section {
      margin-top: 90px;
    }
}