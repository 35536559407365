/* EmailVerification.css */
.container {
  background-color: #f4f4f4;
  margin-top: 100px;
}

.header {
    background-color: #7323eb; /* Set the background color */
    color: white; /* Set the text color */
    padding: 10px 0 20px 0; /* Padding for top and bottom */
    width: 100%;  
    text-align: center; /* Center align text */
}

.header-title-container {
    display: flex;
    flex-direction: row; /* Ensures elements stay in a row */
    white-space: nowrap; /* Prevents text from wrapping */
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide overflow to prevent stacking */
    text-overflow: ellipsis; /* Adds ellipsis if text overflows */
}

.privacy-policy .header-title {
    margin: 0;
    padding: 0;
    line-height: 1;
}

/* Specific font styles for each part */
.mplus-font {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 29px;
}

.varela-font {
    font-family: 'Varela Round', sans-serif;
    font-size: 38px;
    margin-bottom: 25px;
}
  
  .varela-quote {
    font-family: 'Varela Round', sans-serif;
    font-size: 18px;
  }

  .status-message {
    font-family: 'Varela Round', sans-serif;
    font-size: 16px;
    text-align: center;
    margin: 20px;  /* Adds margin around the status message */
    padding: 20px; /* Adds padding inside the status message */
    background-color: white; /* Optional: add a background for better contrast */
    border-radius: 8px; /* Optional: adds rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
}

  .signature {
    color: #7323eb;
    font-family: 'Quicksand', sans-serif;
    font-size: .8em;
    position: absolute;
    bottom: 1px;
    right: 10px;
  }
  

  /* Responsive Styles */
@media (max-width: 768px) { /* For mobile screens */

.header-title-container {
      flex-direction: row; /* Stack titles vertically on mobile */
      align-items: center; /* Center align titles */
  }

.header-title {
      letter-spacing: -2px; /* Adjust as needed */
  }

}