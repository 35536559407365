body {
    font-family: "Varela Round", sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #f4f4f4;
}
header {
    background: #333;
    color: #fff;
    padding: 10px 0;
    text-align: center;
}
nav {
    display: flex;
    justify-content: center; /* Center the links horizontally */
    align-items: center; /* Align the links vertically */
}
nav ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0;
    margin: 0;
    display: flex; /* Display list items in a row */
}
nav li {
    margin: 0 15px; /* Space between links */
}
nav a {
    color: white;
    text-decoration: none;
    padding: 10px;
    display: block; /* Make links block-level to add padding */
}
nav a:hover {
    background-color: #555; /* Change background color on hover */
}
main {
    padding: 20px;
}
footer {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
}
