.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

h1 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 900;
    font-size: 2em;
    margin-bottom: 0.5em;
    text-align: center;
}

h2 {
    font-size: 1.5em;
    color: #7323eb;
    margin-top: 1em;
}

p,
ul {
    font-size: 1em;
    margin-bottom: 1em;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

li {
    text-align: left;
}

strong {
    color: #222;
}

.signature {
    font-size: 14px;
    position: absolute;
    bottom: 2px;
    right: 10px;
}

nav a {
    color: white;
    font-family: 'Quicksand', sans-serif;
    font-weight: 900;
    text-decoration: none;
    padding: 10px;
    display: block;
}

nav a:hover {
    background-color: white;
    border-radius: 20px;
}

footer {
    background-color: #f4f4f4;
    color: #7323eb;
    padding: 10px 0 35px;
    text-align: center;
    position: relative; /* Change from absolute */
    margin-top: auto;
    width: 100%;  
}



/* Responsive Styles */
@media (max-width: 768px) { /* For mobile screens */
    .terms-container {
        padding: 10px; /* Reduce padding on mobile for better spacing */
    }

    h1 {
        font-size: 1.5em; /* Smaller heading for mobile */
    }

    h2 {
        font-size: 1.2em; /* Adjust heading sizes for mobile */
    }

    p,
    ul {
        font-size: 0.9em; /* Slightly smaller text on mobile */
    }

    .header-title-container {
        flex-direction: row; /* Stack titles vertically on mobile */
        align-items: center; /* Center align titles */
    }

    nav a {
        padding: 8px; /* Adjust padding for mobile */
        text-align: center; /* Center the link text */
    }


}
